<template>
  <div class="support card">
    <div>
      <portal to="page-call-to-action">
        <ebp-button v-if="$isAdmin" @click="edit">{{ $t("edit") }}</ebp-button>
      </portal>
    </div>
    <div class="card-body">
      <div v-html="html"></div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "support",
  components: {},
  data() {
    return {
      html: ""
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    edit() {
      this.$router.push({ name: "AdminSupportEdit" });
    },
    async show() {
      api.get("/supports").then(res => {
        const data = res.data;
        if (data.data) {
          this.html = data.data.html;
        }
      });
    }
  }
};
</script>
